<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div
    class="flex h-screen w-full bg-img vx-row no-gutter justify-center"
    id="page-login"
  >
    <div class="container">
      <div class="vx-col lg:w-1/2 sm:m-0">
        <vx-card>
          <div slot="no-body" class="full-page-bg-color">
            <div class="vx-row no-gutter justify-center items-center">
              <div class="vx-col w-full d-theme-dark-bg border rounded">
                <div class="login-tabs-container border rounded">
                  <div class="vx-card__title">
                    <h2 align="center">
                      Enter 4 digit code received via email.
                    </h2>
                  </div>
                  <div>
                    <vs-alert
                      id="alert"
                      v-if="this.otpError === false"
                      :active.sync="showDismissibleAlert"
                      closable
                      icon-pack="feather"
                      close-icon="icon-x"
                      color="success"
                      >{{ message }}</vs-alert
                    >
                    <vs-alert
                      v-else
                      :active.sync="showDismissibleAlert"
                      closable
                      icon-pack="feather"
                      close-icon="icon-x"
                      color="danger"
                      >{{ message }}</vs-alert
                    >
                    <br />
                    <div
                      class="vx-row w-full d-theme-dark-bg border rounded verification-row"
                    >
                      <!-- <div class="input-wrap"> -->
                      <div style="display: flex; flex-direction: row;">
                        <v-otp-input
                          ref="otpInput"
                          input-classes="otp-input"
                          separator=""
                          v-model="otp"
                          :num-inputs="4"
                          :should-auto-focus="true"
                          :is-input-num="true"
                          @on-change="handleOnChange"
                        />
                      </div>

                      <!-- </div> -->
                    </div>
                    <div class="input-wrap">
                      <vs-button
                        class="flex flex-wrap justify-content-center mt-10 mb-6"
                        :disabled="!isDisabled || disableButton"
                        @click="submitOTP"
                        >Login</vs-button
                      >
                      <vs-button
                        v-if="timeLeft <= 0"
                        class="flex flex-wrap justify-content-center mb-6"
                        @click="didClickResendCode"
                        >Resend Code</vs-button
                      >
                      <div
                        v-if="timeLeft > 0"
                        class="vx-row no-gutter justify-center items-center mt-10 mb-6"
                      >
                        <span>
                          Resend in:
                          {{ timeLeftText }}
                        </span>
                      </div>

                      <vs-button
                        type="border"
                        @click="$router.go(-1)"
                        class="flex flex-wrap justify-content-center"
                        >Back To Login</vs-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      email: "",
      otp: 0,
      otpError: false,
      showDismissibleAlert: false,
      message: "",
      disableButton: false,
      isCodeValid: true,
      timeLeft: null,
      timer: null,
      deviceId: window.navigator.userAgent,
      expiresAt: null,
      minutesLeft: null,
      secondsLeft: null,
      timeLeftText: "",
      expirsTime: null
    };
  },

  mounted() {
    this.otp = 0;
    this.email = this.$route.query.email;
    this.expiresAt =
      localStorage.getItem("expiresTime") ||
      new Date(this.$route.query.expiresAt).getTime();
    this.deviceId = window.navigator.userAgent;
    const currentTime = new Date().getTime();
    this.timeLeft = this.expiresAt - currentTime;
    // this.timeLeft = this.expiresBy;

    if (this.timeLeft > 0) {
      this.startTimer();
    }
  },
  computed: {
    isDisabled() {
      return this.otp.length === 4;
    }
  },
  methods: {
    ...mapActions("child", ["getChildListByIds"]),
    async didClickResendCode() {
      await this.resend2fa();
      // localStorage.setItem("timeLeft", this.timeLeft.toString());
    },
    startTimer() {
      this.updateTimerForResendCode();
      this.timer = setInterval(this.updateTimerForResendCode, 1000);
    },
    updateTimerForResendCode() {
      if (this.timeLeft > 0) {
        const totalSeconds = Math.floor(this.timeLeft / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        this.timeLeft -= 1000;
        localStorage.setItem("timeLeft", this.timeLeft.toString());
        this.timeLeftText = `${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      } else {
        clearInterval(this.timer);
        // this.timeLeftText ='00:00'
        this.timer = null;
        this.timeLeft = null;
        localStorage.removeItem("timeLeft");
      }
    },
    handleOnChange(value) {
      this.otp = value;
    },
    resend2fa() {
      const payload = {
        browserDetail: this.deviceId,
        email: this.email
      };
      this.$vs.loading();
      this.$store
        .dispatch("auth/resend2fa", payload)
        .then(async response => {
          this.expiresAt = new Date(response.data.data.expiresAt).getTime();
          localStorage.setItem("expiresTime", this.expiresAt);
          const currentTime = new Date().getTime();
          this.timeLeft = this.expiresAt - currentTime;
          // this.timeLeft = this.expiresBy;

          this.otp = 0;
          this.showDismissibleAlert = true;
          this.message = response.data.message;
          this.$vs.loading.close();
          this.startTimer();
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.otpError = true;
            this.showDismissibleAlert = true;
            this.message = error.response.data.message;
          } else {
            this.otpError = true;
            this.showDismissibleAlert = true;
            this.message = error.message;
          }
          this.$vs.loading.close();
        });
    },

    submitOTP() {
      const otpValue = this.otp;
      const payload = {
        otp: otpValue,
        deviceId: this.deviceId,
        email: this.email
      };
      this.$vs.loading();
      this.$store
        .dispatch("auth/verify2fa", payload)
        .then(async response => {
          this.otp = false;
          this.showDismissibleAlert = true;
          this.message = response.data.message;
          this.$vs.loading.close();
          this.disableButton = true;
          const user = this.$store.state.AppActiveUser;
          let currentRole = user.userType;

          if (currentRole == "learningCenter") {
            currentRole = user.userRole;
          }

          if (currentRole === "parent" || currentRole === "guardians") {
            let data = {
              childIds: user.children,
              parentId: user._id
            };
            await this.getChildListByIds(data);
          }
          this.$acl.change(currentRole);

          switch (currentRole) {
            case "superAdmin":
            case "admin":
              this.$router.push({ path: "/admin/dashboard" });
              break;
            case "centreDirector":
              if (
                this.$route.query.redirect &&
                this.$route.query.redirect != ""
              ) {
                this.$router.push({ path: this.$route.query.redirect });
              } else {
                this.$router.push({ path: "/director/dashboard" });
              }
              break;
            case "centerAdmin":
              this.$router.push({ path: "/center-admin/dashboard" });
              break;
            case "parent":
            case "guardians":
              this.$router.push({ path: "/parent/dashboard" });
              break;
            case "teacher":
              this.$router.push({ path: "/teacher/dashboard" });
              break;
            default:
              this.$router.push({ path: "/" });
              break;
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.otpError = true;
            this.showDismissibleAlert = true;
            this.message = error.response.data.message;
          } else if (error.response.status === 403) {
            this.otpError = true;
            this.showDismissibleAlert = true;
            this.message = error.response.data.message;
            setTimeout(() => {
              this.$router.push({ path: "/login" });
            }, 2000);
          } else {
            this.otpError = true;
            this.showDismissibleAlert = true;
            this.message = error.message;
          }
          this.$vs.loading.close();
        });
    }
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    localStorage.removeItem("expiresTime");
  }
};
</script>

<style lang="scss">
#page-login {
  background: #ffffff;
  .vx-col {
    .w-full {
      width: 100% !important;
    }
    margin: 0 auto !important;
    width: 100%;
    @media (min-width: 992px) {
      width: 80%;
    }
    @media (min-width: 1200px) {
      width: 67% !important;
    }
    @media (min-width: 1480px) {
      width: 75% !important;
    }
    @media (min-width: 1640px) {
      width: 68% !important;
    }
  }

  .vx-card {
    border-radius: 8px !important;
    box-shadow: 0 2px 8px 3px rgba(0, 0, 0, 0.11);
    margin: 60px 0;
    overflow: hidden;
    @media (max-width: 767px) {
      box-shadow: none;
      margin: 30px 0;
      border-radius: 0;
    }
    @media (min-width: 1200px) {
      margin: 80px 0;
    }
    @media (min-width: 1640px) {
      margin: 90px 0;
    }
  }

  .vs-button {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    padding: 15px !important;
    width: 100%;
  }
}
.verification-row {
  justify-content: center;
  .vs-input--input {
    text-align: center;
  }
}
.vs-input {
  width: 100px;
  margin-right: 10px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.vs-input--error {
  border-color: red;
}

.login-tabs-container {
  padding: 20px;
  width: 100%;
  @media (min-width: 992px) {
    width: 80%;
    padding: 0;
  }
  @media (min-width: 1024px) {
    margin: 60px auto !important;
    width: 70%;
  }
  @media (min-width: 1200px) {
    margin: 70px auto !important;
    width: 67%;
  }
  @media (min-width: 1640px) {
    width: 56%;
    margin: 94px auto !important;
  }
  h1 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
    @media (min-width: 1200px) {
      font-size: 40px;
      line-height: 50px;
    }
    @media (min-width: 1640px) {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 26px;
    }
  }
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
